import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import { enums } from '@pt/env-config';

const state = () => ({
  exploitations: [],
  farms: [],
  crops: [],
  selectedExploitation: {},
  selectedFarm: {},
  selectedCrop: {},
  irrigationPlanStatus: enums.STATUS_NOT_FOUNT,
  fertilizationPlanStatus: enums.STATUS_NOT_FOUNT,
  loadingExploitations: false,
  loadingFarms: false,
  loadingCrops: false,
  hasCropHistory: false
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
