import { api } from '@pt/services';
import { events } from '@pt/utilities';
import { enums, env } from '@pt/env-config';
import storage from '../../mixin/storage';
import { useRoute } from 'vue-router';
const { setters, getter } = storage();

export default {
  setSelectedExploitation({ commit }, value) {
    commit('SET_BY_FIELD', { field: 'selectedExploitation', value: value });
  },
  setSelectedFarm({ commit }, value) {
    commit('SET_BY_FIELD', { field: 'selectedFarm', value: value });
  },
  setSelectedCrop({ commit }, value) {
    commit('SET_BY_FIELD', { field: 'selectedCrop', value: value });
  },
  setExploitations({ commit }, value) {
    commit('SET_BY_FIELD', { field: 'exploitations', value: value });
  },
  setFarms({ commit }, value) {
    commit('SET_BY_FIELD', { field: 'farms', value: value });
  },
  setCrops({ commit }, value) {
    commit('SET_BY_FIELD', { field: 'crops', value: value });
  },
  setLoadingExploitations({ commit }, value) {
    commit('SET_BY_FIELD', { field: 'loadingExploitations', value: value });
  },
  setLoadingFarms({ commit }, value) {
    commit('SET_BY_FIELD', { field: 'loadingFarms', value: value });
  },
  setLoadingCrops({ commit }, value) {
    commit('SET_BY_FIELD', { field: 'loadingCrops', value: value });
  },
  setFertilizationPlanStatus({ commit }, value) {
    commit('SET_BY_FIELD', { field: 'fertilizationPlanStatus', value: value });
  },
  setIrrigationPlanStatus({ commit }, value) {
    commit('SET_BY_FIELD', { field: 'irrigationPlanStatus', value: value });
  },
  setHasCropHistory({ commit }, value) {
    commit('SET_BY_FIELD', { field: 'hasCropHistory', value: value });
  },
  async farmState({ getters }) {
    const setObjTruly = obj => obj && Object.keys(obj).length > 0;
    const exploitation = await getters.getByField('selectedExploitation');
    const farm = await getters.getByField('selectedFarm');
    const crop = await getters.getByField('selectedCrop');
    return {
      exploitation: setObjTruly(exploitation) ? exploitation : undefined,
      farm: setObjTruly(farm) ? farm : undefined,
      crop: setObjTruly(crop) ? crop : undefined
    };
  },
  async getExploitations({ dispatch }, params) {
    dispatch('setLoadingExploitations', true);
    dispatch('setLoadingFarms', true);
    dispatch('setLoadingCrops', true);
    await api.getExploitations().then(res => {
      if (res.count) {
        dispatch('setExploitations', res.result);
        if (params?.route.query?.explotacionId) {
          dispatch(
            'setSelectedExploitation',
            res.result.find(e => e.id.toString() === params.route.query.explotacionId)
          );
        } else if (params?.local && getter('STORAGE_NAVBAR') && res.result.length) {
          const { exploitation } = getter('STORAGE_NAVBAR');
          dispatch('setSelectedExploitation', exploitation ? exploitation : res.result[0]);
        } else if (res.result.length) {
          dispatch('setSelectedExploitation', res.result[0]);
        }
      }
    });
    dispatch('setLoadingExploitations', false);
  },
  async getFarms({ dispatch }, params) {
    dispatch('setLoadingFarms', true);
    dispatch('setLoadingCrops', true);
    if (params?.route.query?.fincaId) {
      const { explotacionId, fincaId } = params.route.query;
      await api.getFarms(explotacionId).then(res => {
        dispatch('setFarms', res.result);
        dispatch(
          'setSelectedFarm',
          res.result.find(e => e.id.toString() === fincaId)
        );
      });
    } else if (
      params?.local &&
      getter('STORAGE_NAVBAR') &&
      getter('STORAGE_NAVBAR')['exploitation'] &&
      getter('STORAGE_NAVBAR')['farm']
    ) {
      const { exploitation, farm } = getter('STORAGE_NAVBAR');
      const ids = {
        exploitation: params?.route.query?.explotacionId ?? exploitation?.id
      };
      if (ids.exploitation != null) {
        await api.getFarms(ids.exploitation).then(res => {
          dispatch('setFarms', res.result);
          dispatch(
            'setSelectedFarm',
            (() => {
              if (params?.route.query?.explotacionId) return res.result[0];
              else return farm || res.result[0];
            })()
          );
          if (!res.count) events.emit(enums.EVT_CHANGED_NAVBAR, { exploitation });
        });
      }
    } else {
      const { exploitation } = await dispatch('farmState');
      const ids = {
        exploitation: params?.route.query?.explotacionId ?? exploitation?.id
      };
      if (ids.exploitation != null) {
        await api.getFarms(ids.exploitation).then(res => {
          dispatch('setFarms', res.result);
          dispatch('setSelectedFarm', res.result[0]);
        });
      } else {
        dispatch('setSelectedFarm', {});
      }
    }
    dispatch('setLoadingFarms', false);
  },
  async getCrops({ dispatch }, params) {
    dispatch('setLoadingCrops', true);
    if (params?.route.query?.cultivoId) {
      const { explotacionId, fincaId, cultivoId } = params.route.query;
      await api.getCrops(explotacionId, fincaId).then(res => {
        dispatch('setCrops', res.result);
        dispatch(
          'setSelectedCrop',
          res.result.find(e => e.id.toString() === cultivoId)
        );
      });
    } else if (
      params?.local &&
      getter('STORAGE_NAVBAR') &&
      getter('STORAGE_NAVBAR')['exploitation'] &&
      getter('STORAGE_NAVBAR')['farm'] &&
      getter('STORAGE_NAVBAR')['crop']
    ) {
      const { exploitation, farm, crop } = getter('STORAGE_NAVBAR');
      const ids = {
        exploitation: params?.route.query?.explotacionId ?? exploitation?.id,
        farm: params?.route.query?.fincaId ?? farm?.id
      };
      if (ids.exploitation != null && ids.farm != null) {
        await api.getCrops(ids.exploitation, ids.farm).then(res => {
          dispatch('setCrops', res.result);
          dispatch(
            'setSelectedCrop',
            (() => {
              const findCrop = res.result.find(newCrop => newCrop.id === crop.id);
              if (params?.route.query?.fincaId) return res.result[0];
              else return findCrop || crop || res.result[0];
            })()
          );
          if (!res.count) events.emit(enums.EVT_CHANGED_NAVBAR, { exploitation, farm });
        });
      }
    } else {
      const { exploitation, farm } = await dispatch('farmState');
      const ids = {
        exploitation: params?.route.query?.explotacionId ?? exploitation?.id,
        farm: params?.route.query?.fincaId ?? farm?.id
      };
      if (ids.exploitation != null && ids.farm != null) {
        await api.getCrops(ids.exploitation, ids.farm).then(res => {
          dispatch('setCrops', res.result);
          dispatch('setSelectedCrop', res.result[0]);
        });
      } else {
        dispatch('setSelectedCrop', {});
      }
    }
    dispatch('setLoadingCrops', false);
  },
  async getIrrigationPlan({ dispatch }) {
    const { exploitation, farm, crop } = await dispatch('farmState');
    if (exploitation && farm && crop) {
      await api.getIrrigationPlans(exploitation.id, farm.id, crop.id).then(async res => {
        if (res.count > 0) {
          setters('STORAGE_IRRIGATION_PLAN', res.result[0]);
          await dispatch('postIrrigationPrediction', res.result[0]);
        } else {
          setters('STORAGE_IRRIGATION_PLAN', {});
          setters('STORAGE_STATUS_FORECAST_IRRIGATION', enums.STATUS_NOT_FOUNT);
          dispatch('setIrrigationPlanStatus', enums.STATUS_NOT_FOUNT);
        }
      });
    } else {
      dispatch('setIrrigationPlanStatus', enums.STATUS_NOT_FOUNT);
    }
  },
  async postIrrigationPrediction({ dispatch }, plan) {
    const { exploitation, farm, crop } = await dispatch('farmState');
    if (exploitation && farm && crop) {
      await api
        .postIrrigationPrediction(exploitation.id, farm.id, crop.id, plan.id)
        .then(res => {
          setters('STORAGE_STATUS_FORECAST_IRRIGATION', enums.STATUS_LOADED);
          dispatch('setIrrigationPlanStatus', enums.STATUS_LOADED);
          setters('STORAGE_FORECAST_IRRIGATION', res.result);
        })
        .catch(() => {
          setters('STORAGE_STATUS_FORECAST_IRRIGATION', enums.STATUS_NOT_FOUNT);
          dispatch('setIrrigationPlanStatus', enums.STATUS_NOT_FOUNT);
          setters('STORAGE_FORECAST_IRRIGATION', []);
        });
    }
  },
  async getFertilizationPlan({ dispatch }) {
    const { exploitation, farm, crop } = await dispatch('farmState');
    if (exploitation && farm && crop) {
      await api.getFertilizationPlans(exploitation.id, farm.id, crop.id).then(async res => {
        if (res.count > 0 && res.result[0].modo === 1) {
          setters('STORAGE_FERTILIZATION_PLAN', res.result[0]);
          await dispatch('getFertilizationPrediction', res.result[0]);
        } else {
          setters('STORAGE_FERTILIZATION_PLAN', {});
          setters('STORAGE_STATUS_FORECAST_FERTILIZATION', enums.STATUS_NOT_FOUNT);
          dispatch('setFertilizationPlanStatus', enums.STATUS_NOT_FOUNT);
        }
      });
    } else {
      dispatch('setFertilizationPlanStatus', enums.STATUS_NOT_FOUNT);
    }
  },
  async getFertilizationPrediction({ dispatch }, plan) {
    const { exploitation, farm, crop } = await dispatch('farmState');
    if (exploitation && farm && crop) {
      await api
        .getFertilizationPrediction(exploitation.id, farm.id, crop.id, plan.id)
        .then(res => {
          if (
            res.count > 0 &&
            Object.values(res.result.map(item => ({ ...item, msg: null }))[0]).some(item => item)
          ) {
            setters('STORAGE_STATUS_FORECAST_FERTILIZATION', enums.STATUS_LOADED);
            dispatch('setFertilizationPlanStatus', enums.STATUS_LOADED);
            setters('STORAGE_FORECAST_FERTILIZATION', res.result[0]);
          } else {
            setters('STORAGE_STATUS_FORECAST_FERTILIZATION', enums.STATUS_NOT_FOUNT);
            dispatch('setFertilizationPlanStatus', enums.STATUS_NOT_FOUNT);
            setters('STORAGE_FORECAST_FERTILIZATION', []);
          }
        })
        .catch(() => {
          setters('STORAGE_STATUS_FORECAST_FERTILIZATION', enums.STATUS_NOT_FOUNT);
          dispatch('setFertilizationPlanStatus', enums.STATUS_NOT_FOUNT);
          setters('STORAGE_FORECAST_FERTILIZATION', []);
        });
    }
  },
  async getFinalizedCrops({ dispatch }) {
    dispatch('setHasCropHistory', false);
    const { exploitation, farm } = getter('STORAGE_NAVBAR')
      ? getter('STORAGE_NAVBAR')
      : await dispatch('farmState');
    if (exploitation && farm) {
      api.getFinalizedCrops(exploitation.id, farm.id).then(res => {
        if (res.count) dispatch('setHasCropHistory', true);
        else dispatch('setHasCropHistory', false);
      });
    }
  },
  async loadData({ dispatch, getters }) {
    const route = useRoute();
    setters('STORAGE_STATUS_FORECAST_IRRIGATION', enums.STATUS_LOADING);
    setters('STORAGE_STATUS_FORECAST_FERTILIZATION', enums.STATUS_LOADING);
    await Promise.all([
      await dispatch('getExploitations', { local: true, route }),
      await dispatch('getFarms', { local: true, route }),
      await dispatch('getCrops', { local: true, route })
    ]).then(async () => {
      const { exploitation, farm, crop } = await dispatch('farmState');
      const navbarData = { exploitation, farm, crop };
      if (farm && getters.getByField('farms').length) {
        if (env.APP_FINISHED_CROPS_FLAG) {
          dispatch('getFinalizedCrops');
        }
      }
      if (crop && getters.getByField('crops').length) {
        dispatch('loadPlans', false);
      }
      setters('STORAGE_NAVBAR', navbarData);
      events.emit(enums.EVT_LOAD_DATA);
      events.emit(enums.EVT_CHANGED_NAVBAR, navbarData);
    });
  },
  async changeOption({ dispatch }, value) {
    events.emit(enums.EVT_CHANGED_LOADING, true);
    if (value === 'exploitation') {
      dispatch('setLoadingFarms', true);
      dispatch('setLoadingCrops', true);
      await dispatch('getFarms');
      await dispatch('getCrops');
    } else if (value === 'farm') {
      dispatch('setLoadingCrops', true);
      await dispatch('getCrops');
    }
    dispatch('loadPlans');
    const { exploitation, farm, crop } = await dispatch('farmState');
    const navbarData = { exploitation, farm, crop };
    events.emit(enums.EVT_CHANGED_NAVBAR, navbarData);
    setters('STORAGE_NAVBAR', navbarData);

    if (env.APP_FINISHED_CROPS_FLAG) {
      dispatch('getFinalizedCrops');
    }
  },
  async loadPlans({ dispatch }, loader = true) {
    if (loader) {
      setters('STORAGE_STATUS_FORECAST_IRRIGATION', enums.STATUS_LOADING);
      setters('STORAGE_STATUS_FORECAST_FERTILIZATION', enums.STATUS_LOADING);
    }
    events.emit(enums.EVT_CHANGED_PLANS);
    await Promise.all([dispatch('getIrrigationPlan'), dispatch('getFertilizationPlan')]).finally(
      () => {
        events.emit(enums.EVT_CHANGED_PLANS);
      }
    );
  }
};
